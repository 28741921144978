body {
  margin: 0;
  font-family: 'Epilogue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: "#C7D49A";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:hover, a:visited, a:active {
  color: inherit;
  text-decoration: none;
 }

 .test {
   background-color: red;
 }

 .filter-green{
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.filter-lightyellow {
  filter: brightness(0) saturate(100%) invert(90%) sepia(7%) saturate(1219%) hue-rotate(28deg) brightness(90%) contrast(94%)
}

.filter-mustardyellow {
  filter: brightness(0) saturate(100%) invert(70%) sepia(20%) saturate(7500%) hue-rotate(13deg) brightness(104%) contrast(99%)
}

.filter-blue {
  filter: brightness(0) saturate(100%) invert(16%) sepia(6%) saturate(4577%) hue-rotate(171deg) brightness(94%) contrast(90%)
}
.filter-lightblue {
  filter: brightness(0) saturate(100%) invert(78%) sepia(5%) saturate(977%) hue-rotate(137deg) brightness(95%) contrast(94%)
}

.interacter{

}

.interacter:hover {
  transform: scale(1.05);
  transition: transform 300ms ease-in-out;
}